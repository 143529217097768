import { useFormik } from "formik";
import styles from "./Form.module.css";
import { FormType, MyFormValues } from "../Types";
import { useState } from "react";
import logo from "../assets/logo.png";

const validate = (values: MyFormValues) => {
  const errors: { [key: string]: string } = {};
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  if (!values.description) {
    errors.description = "Description is required";
  }

  if (!values.email || !emailRegex.test(values.email)) {
    errors.email = "Please enter a valid email";
  }

  return errors;
};

export default function Form({
  title,
  description,
  placeholder,
  buttonText,
}: FormType) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      description: "",
      email: "",
    },
    validate,
    onSubmit: async (values) => {
      setSubmitting((init) => !init);
      let data = {
        description: values.description,
        email: values.email,
      };

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let req = await fetch(
        `https://formspree.io/f/${process.env.REACT_APP_FORMSPREE_ID}`,
        options
      );
      console.log(req);
      if (req.status === 200) {
        setMessageSent((init) => !init);
        return setSubmitting((init) => !init);
      }
    },
  });

  return (
    <form className={styles.Form} onSubmit={formik.handleSubmit}>
      <img src={logo} alt="logo" />
      <section className={!messageSent ? styles.fadeIn : styles.fadeOut}>
        <h2>{title}</h2>
        <p>{description}</p>
        <div>
          <label>Description</label>
          <textarea
            id="description"
            name="description"
            rows={6}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            placeholder={placeholder}
          ></textarea>
          {formik.touched.description && formik.errors.description && (
            <span className={styles.errorMessage}>
              {formik.errors.description}
            </span>
          )}
        </div>
        <div>
          <label>Email</label>
          <input
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email && (
            <span className={styles.errorMessage}>{formik.errors.email}</span>
          )}
        </div>
        <button type="submit" disabled={!formik.isValid || submitting}>
          {submitting ? "Submitting..." : buttonText}
        </button>
      </section>
      <section className={messageSent ? styles.fadeIn : styles.fadeOut}>
        <h2>Thank you! 🤘</h2>
        <p>Your message has been sent. You will recieve answer really soon!</p>
        <button
          type="button"
          className={styles.compact}
          onClick={() => {
            formik.resetForm();
            setMessageSent((init) => !init);
          }}
        >
          Send new message
        </button>
      </section>
    </form>
  );
}
