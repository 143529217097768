import Form from "./Component/Form";
import { Helmet } from "react-helmet";
import { FormType, SiteTypes } from "./Types";

const formEntries: {
  [key in SiteTypes]: FormType;
} = {
  nft: {
    title: "Request and describe the NFT you want created for you",
    buttonText: "Request to create an NFT",
    placeholder: "What do you want your NFT to be ?",
    description:
      "Create an NFT of fan art, merchandise, promos, experiences with fans, or anything else.",
  },
  merch: {
    title: "Request personalized merch",
    buttonText: "Request personalized merch",
    placeholder: "What do you want your merch to look like",
    description:
      "Create a collection of personalized merch. Describe how you want your collection to look",
  },
  website: {
    title: "Request to create your own personal website",
    buttonText: "Request to create a website",
    placeholder: "How do you want your website to look like ?",
    description:
      "Describe the features you would like in your own personal website",
  },
};
const formType = process.env.REACT_APP_FORM_TYPE as SiteTypes;

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>GoFanClub - {formEntries[formType].title}</title>
      </Helmet>
      <Form
        title={formEntries[formType].title}
        buttonText={formEntries[formType].buttonText}
        description={formEntries[formType].description}
        placeholder={formEntries[formType].placeholder}
      />
    </div>
  );
}

export default App;
